import * as Colors from "../../../../../../globalStyles/administratorColors";

export const columnRight = {
    textAlign: "right"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const cardHeaderStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer",
    fontWeight: "bold"
};

export const body = {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    padding: "0"
};

export const menuContainer = {
    flex: "1",
    overflow: "auto"
};
