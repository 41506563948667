import React from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

//STYLE
import * as AdminNavStyles from "./styles/navBar";

function Admin_NavBar(props) {
    const GS_navSettings = useSelector((state) => state.GS_navSettings);

    function getFunction(methodInfo) {
        // console.log(methodInfo);
        if (methodInfo.method == "changeSection") {
            return props.changeSection.bind(this, methodInfo.params[0]);
        }
    }

    return (
        <div>
            {props.items.map((item, indexT) => {
                if (item.subMenu) {
                    //ITEM WITH SUB MENU
                    const items = [item, ...item.subMenuItems];
                    return (
                        <div key={indexT}>
                            {items.map((subItem, index) => {
                                if (index === 0) {
                                    return (
                                        <div key={`${index}m`}>
                                            {props.size == 3 ? (
                                                <div>
                                                    <Row key={index}>
                                                        <Col
                                                            className={
                                                                GS_navSettings.selectedItem === item.title
                                                                    ? "customCSS-admin-menu-item-selected"
                                                                    : "customCSS-admin-menu-item"
                                                            }
                                                            onClick={props.subMenuSelect.bind(this, item.subMenuType, item.title.toLowerCase())}
                                                        >
                                                            {/* SUB MENU TITLE */}
                                                            {subItem.title}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Row>
                                                        <Col style={AdminNavStyles.mainMenuItemSmall}>Empty</Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                    );
                                } else {
                                    if (GS_navSettings.selectedItem === item.title) {
                                        if (item.subMenuType == "component" || item.subMenuType == "sideMenu") {
                                            return (
                                                <div key={`${index}s`}>
                                                    {props.size == 3 ? (
                                                        <div>
                                                            <Row key={index}>
                                                                <Col
                                                                    className={
                                                                        GS_navSettings.selectedSubItem === subItem.title
                                                                            ? "customCSS-admin-menu-item-sub-selected"
                                                                            : "customCSS-admin-sub-menu-item"
                                                                    }
                                                                    onClick={props.openSubItem.bind(
                                                                        this,
                                                                        item.title.toLowerCase(),
                                                                        subItem.methodId,
                                                                        subItem.floater
                                                                    )}
                                                                >
                                                                    {subItem.title}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <Row>
                                                                <Col style={AdminNavStyles.mainMenuItemSmall}>Empty</Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                    }
                                }
                            })}
                        </div>
                    );
                } else {
                    // ITEM WITHOUT SUB MENU
                    return (
                        <div key={`${indexT}t`}>
                            {props.size == 3 ? (
                                <div>
                                    <Row>
                                        <Col
                                            className={
                                                GS_navSettings.selectedItem === item.title
                                                    ? "customCSS-admin-menu-item-selected"
                                                    : "customCSS-admin-menu-item"
                                            }
                                            onClick={item.title == "Log Out" ? item.methodInfo.method : getFunction(item.methodInfo)}
                                        >
                                            {item.title}
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div>
                                    <Row>
                                        <Col style={AdminNavStyles.mainMenuItemSmall}>Empty</Col>
                                    </Row>
                                </div>
                            )}
                        </div>
                    );
                }
            })}
        </div>
    );
}

export default Admin_NavBar;
