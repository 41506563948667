import React, { useState, useRef, useEffect } from "react";

//STYLES
import * as CardStyles from "../styles/card";

function Card(props) {
    const inside = useRef();
    const [height, setHeight] = useState(89);
    const [borderHeight, setBorderHeight] = useState(3);
    const [transitionStyle, setTransitionStyle] = useState({});
    const [textColor, setTextColor] = useState("white");

    useEffect(() => {
        if (inside.current != null) {
            setHeight(inside.current.offsetHeight);
        }
    }, []);

    useEffect(() => {
        if (props.selected) {
            setTextColor("yellow");
        } else {
            setTextColor("white");
        }
    }, [props.selected]);

    useEffect(() => {
        if (props.open) {
            setTransitionStyle({ transition: "all 0.5s ease-out" });
            setHeight(0);
            setBorderHeight(0);
        } else {
            setHeight(89);
            setBorderHeight(3);
        }
    }, [props.open]);

    return (
        <div style={CardStyles.innerContaner}>
            <div style={CardStyles.cardContainer}>
                {props.text == "highview.image" ? (
                    <div ref={inside} style={{ ...CardStyles.inside, backgroundImage: `url(${props.image}` }}></div>
                ) : (
                    <div ref={inside} style={{ ...CardStyles.inside, fontSize: `${props.fontSize}px` }}>
                        {props.text}
                    </div>
                )}

                <div
                    style={{
                        ...CardStyles.cover,
                        ...transitionStyle,
                        height: height,
                        color: textColor,
                        borderTop: `${borderHeight}px solid white`,
                        borderBottom: `${borderHeight}px solid white`
                    }}
                >
                    {props.showNumber && props.number}
                </div>
            </div>
        </div>
    );
}

export default Card;
