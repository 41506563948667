export const menuContainer = {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "center",
    justifyContent: "center"
};

export const gameContainer = {
    padding: "0px",
    cursor: "pointer"
};

export const gameInnerContainer = {
    border: "1px solid black",
    textAlign: "center",
    padding: "5px",
    margin: "5px"
};

export const gameTitle = {
    fontWeight: "bold"
};

export const gameImage = {
    height: "130px",
    width: "130px"
};
