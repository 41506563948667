import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";

//STYLES
import * as navBarStyles from "./styles/navBar";

//NAV METHODS
import pageNavigation from "./navBarClickMethods";

//COMPONENTS

//IMAGES
import Logo from "../../../../public/images/Scout-Logo.png";

function BrowserNavBar() {
    const [servicesSubMenu, setServicesSubMenu] = useState(false);

    const GS_generalSettings = useSelector((state) => state.GS_generalSettings);

    const navigate = useNavigate();

    function ServicesSubMenuShow() {
        setServicesSubMenu(true);
    }
    function ServicesSubMenuHidden() {
        setServicesSubMenu(false);
    }

    return (
        <div>
            <Row>
                <Col style={navBarStyles.body}>
                    <Row>
                        <Col md={7} style={navBarStyles.title}>
                            ST GILES
                        </Col>
                        <Col style={navBarStyles.navButtons}>Home</Col>
                        <Col style={navBarStyles.navButtons}>
                            Whats On <i className="fa-solid fa-angle-down"></i>{" "}
                        </Col>
                        <Col style={navBarStyles.navButtons}>Links</Col>
                        <Col style={navBarStyles.navButtons}>Events</Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default BrowserNavBar;
