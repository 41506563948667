import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

//ADMINISTRATION SIDE
import AdminHome from "./pages/admin_home";
import AdminLogin from "./pods/security/podRouter";
import PassAccess from "./pages/passAccess";

//STYLE DESIGN
import StyleDesign from "../environment/mainStyle/admin/files/styleDesign";

function AdminRouteSystem(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        onOpen();
        setIsLoaded(true);
        // eslint-disable-next-line
    }, []);

    function onOpen() {
        if (user.auth) {
            console.log(user);
            if (user.type !== "registered") {
                if (
                    location.pathname == `/admin-${props.adminCode}/` ||
                    location.pathname == `/admin-${props.adminCode}` ||
                    location.pathname.includes("security")
                ) {
                    if (user.new) {
                        navigate(`/admin-${props.adminCode}/security/newPassword`);
                    } else if (user.requestedPassword) {
                        navigate(`/admin-${props.adminCode}/security/changePassword`);
                    } else {
                        navigate(`/admin-${props.adminCode}/overview`);
                    }
                } else {
                    if (user.new) {
                        navigate(`/admin-${props.adminCode}/security/newPassword`);
                    } else if (user.requestedPassword) {
                        navigate(`/admin-${props.adminCode}/security/changePassword`);
                    }
                }
            } else {
                navigate(`/admin-${props.adminCode}/security/login`);
            }
        } else {
            const routes = location.pathname.split("/");
            // console.log(routes);

            if (
                routes[routes.length - 1] === "" ||
                routes[routes.length - 1] === "security" ||
                routes[routes.length - 1] === `admin-${props.adminCode}`
            ) {
                navigate(`/admin-${props.adminCode}/security/login`);
            } else if (!location.pathname.includes("security")) {
                navigate(`/admin-${props.adminCode}/security/login`);
            } else {
                navigate(location.pathname);
            }
        }
    }

    return (
        <div>
            {isLoaded ? (
                <div>
                    <Routes>
                        <Route path="/styleConfiguration/design" exact element={<StyleDesign />} />
                        <Route path="/*" element={<AdminHome adminCode={props.adminCode} />} />
                        <Route path="/security/*" element={<AdminLogin />} />
                        <Route path="/pass/auth/access" element={<PassAccess />} />
                    </Routes>
                </div>
            ) : null}
        </div>
    );
}

export default AdminRouteSystem;
