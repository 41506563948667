import React, { useState, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

//ACTION
import * as GamesHubActions from "../../../../envStore/actions/gamesHub";

//STYLES
import * as HubStyles from "../styles/hub";

//COMPONENTS
import MainMenu from "./mainMenu";
import WinThePoints from "../../games/winThePonts/podRouter";

function HubGame() {
    const hubTitleSection = useRef(null);
    const dispatch = useDispatch();

    const gamesHub = useSelector((state) => state.gamesHub);

    useEffect(() => {
        if (hubTitleSection != null) {
            dispatch(GamesHubActions.UpdateHeight(window.innerHeight - hubTitleSection.current.offsetHeight));
        }
    }, []);

    return (
        <div style={{ ...HubStyles.body, backgroundColor: gamesHub.backgroundColor }}>
            <Row>
                <Col sm={2}></Col>
                <Col ref={hubTitleSection} style={{ ...HubStyles.mainTitle, color: gamesHub.titleColor }}>
                    {gamesHub.title}
                </Col>
                <Col sm={2} style={HubStyles.columnRight}>
                    {gamesHub.showHelp && (
                        <Button variant={gamesHub.helpVariant} onClick={gamesHub.helpMethod}>
                            Help
                        </Button>
                    )}
                </Col>
            </Row>
            <Row>
                <Col style={{ height: gamesHub.height }}>
                    <Routes>
                        <Route path="/" element={<MainMenu />} />
                        <Route path="/winThePoints/*" element={<WinThePoints />} />
                    </Routes>
                </Col>
            </Row>
        </div>
    );
}

export default HubGame;
