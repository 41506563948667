export const body = {};

export const mainTitle = {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "40px"
};

export const columnRight = {
    textAlign: "right"
};
