import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import MainMenu from "./gameMenu";
import WinThePoints from "./frontEnd/files/winThePoints";
import WinThePointsAdmin from "./admin/files/controller";
import WinThePointsAdminGameTemplate from "./admin/files/gameTemplate";

function PodRouter(props) {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        <Route path="/" element={<MainMenu />} />
                        <Route path="/game" element={<WinThePoints />} />
                        <Route path="/admin" element={<WinThePointsAdmin />} />
                        <Route path="/admin/newGame" element={<WinThePointsAdminGameTemplate modify={false} />} />
                        <Route path="/admin/modifyGame/:id" element={<WinThePointsAdminGameTemplate modify={true} />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;
