import * as ReducerActions from "../actions/gamesHub";

const initialState = {
    title: "Games Hub",
    height: 16,
    backgroundColor: "#add8e6",
    titleColor: "red",
    showHelp: false,
    helpMethod: null,
    helpVariant: "primary"
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.UPDATE_TITLE:
            return { ...state, title: action.value };
        case ReducerActions.UPDATE_HEIGHT:
            return { ...state, height: action.value };
        case ReducerActions.UPDATE_BACKGROUND_COLOR:
            return { ...state, backgroundColor: action.value };
        case ReducerActions.UPDATE_TITLE_COLOR:
            return { ...state, titleColor: action.value };
        case ReducerActions.UPDATE_SHOW_HELP:
            return { ...state, showHelp: action.value };
        case ReducerActions.UPDATE_HELP_METHOD:
            return { ...state, helpMethod: action.value };
        case ReducerActions.UPDATE_HELP_VARIANT:
            return { ...state, helpVariant: action.value };
        default:
            return state;
    }
};

export default reducer;
