import * as Colors from "../../globalStyles/environmentColors";

// export const body = {
//     minHeight: "100vh",
//     backgroundColor: Colors.background
// };

export const mainBody = {
    // paddingTop: "20px",
    // textAlign: "center"
    height: "100vh"
};

export const mainImage = {
    height: "600px",
    width: "100vw"
};

export const slideShowContainer = {
    // width: "700px",
    // height: "550px"
    // border: "1px solid black"
};
