import * as Colors from "../../../../../globalStyles/environmentColors";

export const formLayout = {
    textAlign: "left",
    fontSize: "1.2rem"
};

export const formHidden = {
    visibility: "hidden"
};

export const firstForm = {
    display: "flex"
};
export const firstFormBtn = {
    padding: "0",
    marginTop: "0"
};

export const firstFormEmail = {
    paddingTop: "10px"
};

export const bodyTextSize = {
    fontSize: "1.2rem"
};

export const registerBtns = {
    textAlign: "right"
};

export const hr = {
    color: "white",
    backgroundColor: "white",
    height: "5px"
};

export const registerError = {
    textAlign: "right",
    color: "yellow",
    fontWeight: "bold"
};

export const registerInputError = {
    color: "yellow",
    textAlign: "left"
};

export const registerInputErrorHidden = {
    visibility: "hidden"
};

export const FP_formTextBox = {
    width: "50%",
    margin: "auto"
};

export const FP_InputError = {
    color: "yellow",
    textAlign: "right",
    fontWeight: "bold",
    width: "50%",
    margin: "auto"
};

export const passwordNotice = {
    color: "yellow",
    fontWeight: "bold"
};

export const top_Text = {
    fontSize: "1.5rem"
};

export const body = {
    backgroundColor: Colors.background,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
};

export const centerContainer = {
    backgroundColor: Colors.primary,
    textAlign: "center",
    color: "white",
    border: `2px solid ${Colors.primary}`,

    borderRadius: "25px",

    padding: "30px",
    fontSize: "18px",
    marginTop: "10px",
    width: "60%"
};

export const title = {
    fontWeight: "bold",
    fontSize: "50px"
};
