import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Switch from "react-switch";

//ACTIONS
import * as GamesHubActions from "../../../../../../envStore/actions/gamesHub";

//STYLES
import * as ControllerStyles from "../styles/controller";

function Contoller() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const gamesHub = useSelector((state) => state.gamesHub);
    const user = useSelector((state) => state.user);
    const [games, setGames] = useState([]);

    useEffect(() => {
        dispatch(GamesHubActions.UpdateTitle("Win the Points - Administrator"));
        dispatch(GamesHubActions.UpdateBackgroundColor("#add8e6"));
        dispatch(GamesHubActions.UpdateTitleColor("red"));
        dispatch(GamesHubActions.UpdateShowHelp(false));

        loadGames();
    }, []);

    function loadGames() {
        const data = { userID: user.id };
        Axios.post("/pods/winThePointsAdmin/loadAllGames", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    console.log(data.games);
                    setGames(data.games);
                }
            })
            .catch((err) => console.log(data));
    }

    function handleNewGameClick() {
        navigate("./newGame");
    }

    function handleGameClick(id) {
        navigate(`./modifyGame/${id}`);
    }

    function handleLiveSwitchChange(id, checked) {
        const data = { userID: user.id, gameID: id, live: checked };
        Axios.post("/pods/winThePointsAdmin/updateLive", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    loadGames();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div>
            <Row>
                <Col sm={3}></Col>
                <Col style={{ ...ControllerStyles.body, height: gamesHub.height }}>
                    <div style={ControllerStyles.columnRight}>
                        <Button onClick={handleNewGameClick}>New Game</Button>
                    </div>
                    <br />
                    <Row>
                        <Col>
                            <Card style={ControllerStyles.cardHeaderStyle}>
                                <Card.Body>
                                    <Row>
                                        <Col>File</Col>
                                        <Col style={ControllerStyles.columnRight}>Live</Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <div style={ControllerStyles.menuContainer}>
                        <br />
                        {games.map((game, index) => {
                            return (
                                <div key={index}>
                                    <Card style={ControllerStyles.cardStyle}>
                                        <Card.Body>
                                            <Row>
                                                <Col sm={10} onClick={handleGameClick.bind(this, game.gameID)}>
                                                    {game.gameFileName}
                                                </Col>
                                                <Col style={ControllerStyles.columnRight}>
                                                    <Switch
                                                        className="customCSS-Content-New-Item-Switch"
                                                        onChange={handleLiveSwitchChange.bind(this, game.gameID)}
                                                        checked={game.live}
                                                        checkedIcon={false}
                                                        uncheckedIcon={false}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </div>
                            );
                        })}
                    </div>
                    {/* </Row> */}
                </Col>
                <Col sm={3}></Col>
            </Row>
        </div>
    );
}

export default Contoller;
