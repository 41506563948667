import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Col, Image, Modal, Button } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GamesHubActions from "../../../../envStore/actions/gamesHub";

//STYLES
import * as MainMenuStyles from "./admin/styles/gameMenu";

function GameMenu() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [games, setGames] = useState([
        { title: "Play Game", image: "winThePoints/mainLogo.png", link: "game" },
        { title: "Admin", image: "winThePoints/mainLogo.png", link: "admin" }
    ]);
    const gamesHub = useSelector((state) => state.gamesHub);
    const user = useSelector((state) => state.user);

    const [columnWidth, setColumnWidth] = useState(12);
    const [rowWidth, setRowWidth] = useState("25%");

    const [modal, setModal] = useState({
        open: false,
        header: "",
        message: ""
    });

    function handleModalOnClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GamesHubActions.UpdateTitle("Win the Points"));
        dispatch(GamesHubActions.UpdateBackgroundColor("#add8e6"));
        dispatch(GamesHubActions.UpdateTitleColor("red"));

        if (games.length == 1) {
            setColumnWidth(12);
            setRowWidth("25%");
        } else if (games.length == 2) {
            setColumnWidth(6);
            setRowWidth("50%");
        } else if (games.length == 3) {
            setColumnWidth(4);
            setRowWidth("75%");
        } else {
            setColumnWidth(3);
            setRowWidth("100%");
        }
    }, []);

    function handleGoToGame() {
        const data = { userID: user.id };
        Axios.post("/pods/winThePointsAdmin/checkLiveGame", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "Yes") {
                    setModal({ header: "Win The Points: Error", message: data.message, open: true });
                } else {
                    navigate(`./${games[0].link}`);
                }
            })
            .catch((err) => console(err));
    }

    function handleGoToAdmin() {
        navigate(`./${games[1].link}`);
    }

    return (
        <div>
            <Row>
                <Col sm={2}></Col>
                <Col style={{ ...MainMenuStyles.menuContainer, height: gamesHub.height }}>
                    <div style={{ width: rowWidth }}>
                        <Row>
                            <Col sm={columnWidth} style={MainMenuStyles.gameContainer} onClick={handleGoToGame}>
                                <div style={MainMenuStyles.gameInnerContainer}>
                                    <p style={MainMenuStyles.gameTitle}>{games[0].title}</p>
                                    <Image style={MainMenuStyles.gameImage} src={`/content/gamesHub/${games[0].image}`} />
                                </div>
                            </Col>
                            <Col sm={columnWidth} style={MainMenuStyles.gameContainer} onClick={handleGoToAdmin}>
                                <div style={MainMenuStyles.gameInnerContainer}>
                                    <p style={MainMenuStyles.gameTitle}>{games[1].title}</p>
                                    <Image style={MainMenuStyles.gameImage} src={`/content/gamesHub/${games[1].image}`} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm={2}></Col>
            </Row>
            <Modal show={modal.open} onHide={handleModalOnClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleModalOnClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default GameMenu;
