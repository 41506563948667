export const centerContainer = {
    height: "100%"
};

export const placement = {
    border: "1px solid black",
    height: "125px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: 0,
    margin: 0
};

export const selectedPlacement = {
    border: "1px solid black",
    height: "125px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: 0,
    margin: 0,
    backgroundColor: "rgba(0,0,255,0.3)"
};

export const controlBox = {
    backgroundColor: "white",
    backgroundSize: "cover",
    width: "50%",
    height: "70%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textAlign: "center",
    wordBreak: "break-all"
};

export const innerContaner = {
    width: "100%",
    height: "100%",
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const titleContainer = {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "5px"
};

export const previewBox = {
    backgroundColor: "white",
    width: "50%",
    height: "70%",
    position: "absolute",
    zindex: "0",
    top: 19,
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "center",
    wordBreak: "break-all",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "14px"
};

export const controlsContainer = {
    padding: "15px"
};

export const columnRight = {
    textAlign: "right"
};

export const fontContainer = {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "18px"
};

export const rightContainer = {
    height: "625px",
    display: "flex",
    flexDirection: "column"
};

export const fileNameContainer = {
    paddingRight: "30px",
    fontSize: "18px"
};

export const saveButtonContainer = {
    position: "absolute",
    bottom: "0",
    textAlign: "right",
    paddingRight: "30px"
};

export const counter = {
    textAlign: "right",
    color: "red"
};

export const liveContainer = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "15px"
};

export const input = {
    display: "none"
};
