import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";

//ACTIONS
import * as UserActions from "../../store/actions/user";
import * as GS_navSettingsActions from "../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as AdminHomeStyles from "./styles/admin_home";

//COMPONENTS
import AdminNavBar from "../pods/navBar/navBar";

import AdminNoneItems from "../pods/navBar/noneItems";

import AdminOverview from "../pods/overview/overview";
import AdminUsers from "../pods/users/podRouter";
import AdminEditorials from "../pods/editorials/podRouter";
import AdminMedia from "../pods/media/media";
import AdminContacts from "../pods/contacts/files/contactsControl";
import AdminPages from "../pods/pages/podRouter";
import AdminFeatures from "../pods/features/podRouter";
import AdminStyleConfig from "../pods/styleConfig/styleArea";
import AdminHelp from "../pods/help/files/controller";

function AdminHome(props) {
    const GS_navSettings = useSelector((state) => state.GS_navSettings);
    const user = useSelector((state) => state.user);
    const [overlay, setOverlay] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const menuItems = {
        overview: {
            id: 0,
            title: "Overview",
            subMenu: false,
            subMenuItems: [],
            methodInfo: {
                method: "changeSection",
                params: ["overview"]
            }
        },
        users: {
            id: 1,
            title: "Users",
            subMenu: true,
            subMenuType: "component",
            subMenuItems: [
                {
                    id: 0,
                    title: "General Settings",
                    methodId: "generalsettings",
                    floater: false
                }
            ]
        },
        pages: {
            id: 2,
            title: "Pages",
            subMenu: true,
            subMenuItems: []
        },
        editorials: {
            id: 3,
            title: "Editorials",
            subMenu: false,
            subMenuItems: [],
            methodInfo: {
                method: "changeSection",
                params: ["editorials"]
            }
        },
        media: {
            id: 4,
            title: "Media",
            subMenu: false,
            subMenuItems: [],
            methodInfo: {
                method: "changeSection",
                params: ["media"]
            }
        },
        contacts: {
            id: 5,
            title: "Contacts",
            subMenu: false,
            subMenuItems: [],
            methodInfo: {
                method: "changeSection",
                params: ["contacts"]
            }
        },
        features: {
            id: 6,
            title: "Features",
            subMenu: true,
            subMenuItems: []
        },
        style: {
            id: 7,
            title: "Style Configuration",
            subMenu: false,
            methodInfo: {
                method: "changeSection",
                params: ["styleConfiguration"]
            }
        },
        help: {
            id: 9,
            title: "Help",
            subMenu: false,
            methodInfo: {
                method: "changeSection",
                params: ["help"]
            }
        },
        logOut: {
            id: 9,
            title: "Log Out",
            subMenu: false,
            subMenuItems: [],
            methodInfo: {
                method: handleLogOut
            }
        }
    };

    const [mainMenuItems, setMenuItems] = useState([]);

    useEffect(() => {
        onOpen();
        // eslint-disable-next-line
    }, []);

    function onOpen() {
        if (!user.auth) {
            navigate(`/admin-${props.adminCode}/security/login`);
        } else if (user.type === "registered") {
            navigate(`/admin-${props.adminCode}/security/login`);
        } else {
            Axios.post("/adminPods/admin/getPagesAndFeatures")
                .then((res) => {
                    const data = res.data;
                    if (data.error === "null") {
                        let pagesItem = { ...menuItems["pages"] };
                        let featuresItem = { ...menuItems["features"] };

                        if (data.pages.error != "no pages") {
                            if (data.pages.type == "sideMenu") {
                                const subMenu = [];
                                data.pages.items.map((page) => {
                                    const subItem = {
                                        id: page.uuid,
                                        title: page.title,
                                        methodId: page.uuid,
                                        floater: false
                                    };
                                    subMenu.push(subItem);
                                });

                                pagesItem.subMenuItems = subMenu;
                                pagesItem.subMenuType = "sideMenu";
                            } else {
                                pagesItem.subMenuItems = data.pages.items;
                                pagesItem.subMenuType = "display";
                            }
                        } else {
                            pagesItem.subMenuType = "none";
                        }
                        if (data.features.error != "no features") {
                            if (data.features.type == "sideMenu") {
                                const subMenu = [];
                                data.features.items.map((feature) => {
                                    const subItem = {
                                        id: feature.uuid,
                                        title: feature.title,
                                        methodId: feature.adminCode,
                                        floater: false
                                    };
                                    subMenu.push(subItem);
                                });

                                featuresItem.subMenuItems = subMenu;
                                featuresItem.subMenuType = "sideMenu";
                            } else {
                                featuresItem.subMenuItems = data.features.items;
                                featuresItem.subMenuType = "display";
                            }
                        } else {
                            featuresItem.subMenuType = "none";
                        }
                        const otherMenus = [];
                        for (const [key, menu] of Object.entries(menuItems)) {
                            const items = data.otherMenuItems.filter((men) => men.topMenu == menu.title.toLowerCase());
                            if (items.length > 0) {
                                const modifyMenu = menuItems[menu.title.toLowerCase()];
                                const subMenu = [];
                                for (const item of items) {
                                    const subItem = {
                                        id: item.uuid,
                                        title: item.title,
                                        methodId: item.adminCode,
                                        floater: true
                                    };
                                    subMenu.push(subItem);
                                }
                                modifyMenu.subMenuItems = subMenu;
                                modifyMenu.subMenuType = "component";
                                modifyMenu.subMenu = true;

                                otherMenus.push({
                                    title: menu.title.toLowerCase(),
                                    menuItem: modifyMenu
                                });
                            }
                        }
                        let styleConfig = false;
                        if (data.styleConfig == "true") {
                            styleConfig = true;
                        }
                        BuildAdminMenu(pagesItem, featuresItem, otherMenus, styleConfig);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function BuildAdminMenu(pagesItem, featuresItem, otherMenus, styleConfig) {
        let items = [];
        if (user.type == "seniorAdmin" || user.type == "admin") {
            if (styleConfig) {
                items = ["overview", "users", "pages", "editorials", "media", "contacts", "features", "style", "help", "logOut"];
            } else {
                items = ["overview", "users", "pages", "editorials", "media", "contacts", "features", "help", "logOut"];
            }
        }
        if (user.type == "modifier") {
            items = ["overview", "pages", "editorials", "media", "help", "logOut"];
        }
        const tempMenu = [];

        items.forEach((item) => {
            if (item === "pages") {
                tempMenu.push(pagesItem);
            } else if (item === "features") {
                tempMenu.push(featuresItem);
            } else {
                const menu = otherMenus.find((i) => i.title == item);
                if (menu != null) {
                    tempMenu.push(menu.menuItem);
                } else {
                    tempMenu.push(menuItems[item]);
                }
            }
        });
        setMenuItems(tempMenu);
        setIsLoaded(true);
    }

    function CapsFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    //MENU CHANGE METHODS
    function subMenuSelect(subMenuType, title) {
        if (subMenuType == "none") {
            navigate(`./none/${title}`);
        }
        if (subMenuType == "component") {
            if (overlay) {
                setOverlay(false);
                dispatch(GS_navSettingsActions.ResetPrevSelected());
            }
            navigate(`./${title}`);
        }
        if (subMenuType == "sideMenu") {
            dispatch(GS_navSettingsActions.UpdateSelectedToPrev());
            dispatch(GS_navSettingsActions.UpdateSelected(CapsFirstLetter(title)));
            setOverlay(true);
        }
        if (subMenuType == "display") {
            if (overlay) {
                setOverlay(false);
                dispatch(GS_navSettingsActions.ResetPrevSelected());
            }
            navigate(`./${title}/list`);
        }
    }

    function changeSection(title) {
        console.log("hi");
        if (overlay) {
            setOverlay(false);
            dispatch(GS_navSettingsActions.ResetPrevSelected());
        }
        navigate(`./${title}`);
    }

    function changeSection_CamelCase(title) {
        console.log("bye");
        if (overlay) {
            setOverlay(false);
            dispatch(GS_navSettingsActions.ResetPrevSelected());
        }
        const words = title.split(" ");
        console.log(words);
        navigate(`./${words[0]}`);
    }

    function openSubMenuItem(menu, code, floater) {
        console.log(floater);
        if (overlay) {
            setOverlay(false);
            dispatch(GS_navSettingsActions.ResetPrevSelected());
        }
        if (menu == "pages") {
            navigate(`./pages/settings/${code}`);
        } else if (menu == "features" || floater) {
            navigate(`./features/pod/${code}`);
        } else {
            const link = `${menu}/${code.replace(" ", "")}`;
            navigate(`./${link}`);
        }
    }

    //MAIN MENU ACTIONS
    function handleLogOut() {
        Axios.post("/userLogin/admin/logout")
            .then((res) => {
                const data = res.data;
                if (data.message === "Admin logged out") {
                    dispatch(UserActions.UpdateAuth(false));
                    dispatch(UserActions.UpdateID(""));
                    dispatch(UserActions.UpdateName(""));
                    dispatch(UserActions.UpdateEmail(""));
                    dispatch(UserActions.UpdateType(""));
                    navigate(`/admin-${data.adminCode}/security/login`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleTurnOffOverlay() {
        dispatch(GS_navSettingsActions.UpdatePrevToSelected());
        setOverlay(false);
    }

    return (
        <div style={AdminHomeStyles.body}>
            {isLoaded && (
                <div>
                    <Row>
                        <Col md={GS_navSettings.size}>
                            <Row>
                                <Col style={AdminHomeStyles.title}>{GS_navSettings.size == 3 ? "Administration" : "Admin"}</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AdminNavBar
                                        size={GS_navSettings.size}
                                        items={mainMenuItems}
                                        changeSection={changeSection}
                                        subMenuSelect={subMenuSelect}
                                        openSubItem={openSubMenuItem}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12 - GS_navSettings.size} style={AdminHomeStyles.bodyColumn}>
                            <div style={AdminHomeStyles.mainBodyContainer}>
                                <div style={AdminHomeStyles.mainBody}>
                                    <Row>
                                        <Col style={AdminHomeStyles.sectionTitle}>
                                            {GS_navSettings.static ? GS_navSettings.staticText : GS_navSettings.title}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Routes>
                                                <Route path="/overview" element={<AdminOverview />} />
                                                <Route path="/users/*" exact element={<AdminUsers />} />
                                                <Route path="/editorials/*" element={<AdminEditorials />} />
                                                <Route path="/media" element={<AdminMedia />} />
                                                <Route path="/contacts" element={<AdminContacts />} />
                                                <Route path="/none/:menu" element={<AdminNoneItems />} />
                                                <Route
                                                    path="/pages/*"
                                                    element={
                                                        <AdminPages items={mainMenuItems.find((section) => section.title == "Pages").subMenuItems} />
                                                    }
                                                />
                                                <Route
                                                    path="/features/*"
                                                    element={
                                                        <AdminFeatures
                                                            items={mainMenuItems.find((section) => section.title == "Features").subMenuItems}
                                                        />
                                                    }
                                                />
                                                <Route path="styleConfiguration/*" element={<AdminStyleConfig />} />
                                                <Route path="/help" element={<AdminHelp />} />
                                            </Routes>
                                        </Col>
                                    </Row>
                                </div>
                                {overlay && (
                                    <div style={AdminHomeStyles.overlay} onClick={handleTurnOffOverlay}>
                                        <span style={AdminHomeStyles.overlayText}>Click to go back to this section</span>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default AdminHome;
