import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Switch from "react-switch";

//ACTIONS
import * as GamesHubActions from "../../../../../../envStore/actions/gamesHub";

//STYLES
import * as GameTemplateStyles from "../styles/gameTemplate";

function GameTemplate(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const gamesHub = useSelector((state) => state.gamesHub);
    const user = useSelector((state) => state.user);

    const [selectedCard, setSelectedCard] = useState(0);
    const [live, setLive] = useState(false);

    const [previousData, setPrevouisData] = useState(null);
    const [fileName, setFileName] = useState({
        value: "",
        valid: false,
        touched: false
    });
    const titleTextControl = useRef(null);
    const hiddenInput = useRef(null);

    const [cards, setCards] = useState([
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" },
        { title: "", info: "15", imageFile: null, image: null, imageStatus: "" }
    ]);

    useEffect(() => {
        dispatch(GamesHubActions.UpdateShowHelp(false));

        if (props.modify) {
            dispatch(GamesHubActions.UpdateTitle("Win the Points - Update Game"));
            const data = { gameID: params.id };
            Axios.post("/pods/winThePointsAdmin/loadGame", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "null") {
                        setFileName((prevState) => {
                            return { ...prevState, value: data.details.gameFileName, valid: true, touched: true };
                        });
                        setLive(data.details.live);

                        const temp = JSON.parse(JSON.stringify(cards));
                        for (const [index, card] of temp.entries()) {
                            card.title = data.cards[`card${index + 1}`];
                            card.info = data.cards[`info${index + 1}`];

                            if (data.cards[`card${index + 1}`] == "highview.image") {
                                card.image = data.images[`image${index + 1}`];
                                card.imageStatus = "current";
                            }
                        }

                        setCards((prevState) => {
                            return JSON.parse(JSON.stringify(temp));
                        });
                        setPrevouisData((prevState) => {
                            return JSON.parse(JSON.stringify(temp));
                        });
                    }
                })
                .catch((err) => console.log(err));
        } else {
            dispatch(GamesHubActions.UpdateTitle("Win the Points - New Game"));
        }
    }, []);

    function handleCardClick(index) {
        setSelectedCard(index);
        setTimeout(() => {
            if (cards[index].title != "highview.image") {
                titleTextControl.current.focus();
            }
        }, 10);
    }

    function handleOnChange(event) {
        const { value } = event.target;

        const temp = [...cards];

        temp[selectedCard].title = value;

        setCards(temp);
    }

    function handleCardGoDown() {
        let newNumber = selectedCard - 1;
        if (newNumber < 0) {
            newNumber = 19;
        }
        setSelectedCard(newNumber);
        setTimeout(() => {
            if (cards[newNumber].title != "highview.image") {
                titleTextControl.current.focus();
            }
        }, 10);
    }

    function handleCardGoUp() {
        let newNumber = selectedCard + 1;
        if (newNumber > 19) {
            newNumber = 0;
        }
        setSelectedCard(newNumber);
        setTimeout(() => {
            if (cards[newNumber].title != "highview.image") {
                titleTextControl.current.focus();
            }
        }, 10);
    }

    function handleFontGoDown() {
        let newSize = parseInt(cards[selectedCard].info) - 1;

        if (newSize < 0) {
            newSize = 0;
        }

        const temp = [...cards];

        temp[selectedCard].info = newSize;

        setCards(temp);
    }

    function handleFontGoUp() {
        let newSize = parseInt(cards[selectedCard].info) + 1;

        const temp = [...cards];

        temp[selectedCard].info = newSize;

        setCards(temp);
    }

    function handleFileNameTouched() {
        setFileName((prevState) => {
            return { ...prevState, touched: true };
        });
    }

    function handleFileNameTextChanged(event) {
        const { value } = event.target;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        setFileName((prevState) => {
            return { ...prevState, value: value, valid: isValid };
        });
    }

    function handleSaveButton() {
        if (!fileName.valid) {
            setFileName((prevState) => {
                return { ...prevState, touched: true };
            });
        } else {
            const data = { userID: user.id, fileName: fileName.value, cards: cards, live: live };
            console.log(cards[0].imageFile);
            Axios.post("/pods/winThePointsAdmin/addGame", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        if (checkImagesInCards()) {
                            handleUploadImages(data.gameID);
                        } else {
                            navigate(-1);
                        }
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleUpdateButton() {
        if (!fileName.valid) {
            setFileName((prevState) => {
                return { ...prevState, touched: true };
            });
        } else {
            const data = { userID: user.id, gameID: params.id, fileName: fileName.value, cards: cards, live: live };
            Axios.post("/pods/winThePointsAdmin/updateGame", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        if (checkImagesInCards()) {
                            handleUploadImages(params.id);
                        } else {
                            navigate(-1);
                        }
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function checkImagesInCards() {
        let image = false;
        for (const card of cards) {
            if (card.imageStatus == "create" || card.imageStatus == "update" || card.imageStatus == "delete") {
                image = true;
                break;
            }
        }

        return image;
    }

    function handleLiveSwitchChange(checked) {
        setLive(checked);
    }

    function handleOpenInput() {
        hiddenInput.current.click();
    }

    function handleFileClick(event) {
        const file = event.target.files[0];

        const data = { file: file.name };
        Axios.post("/pods/winThePointsAdmin/getExtName", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const temp = [...cards];
                    temp[selectedCard].imageFile = file;
                    temp[selectedCard].image = URL.createObjectURL(file);
                    temp[selectedCard].title = "highview.image";
                    temp[selectedCard].info = data.extName;
                    if (previousData[selectedCard].imageStatus == "current") {
                        temp[selectedCard].imageStatus = "update";
                    } else {
                        temp[selectedCard].imageStatus = "create";
                    }

                    setCards(temp);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleTextBtnClick() {
        const temp = [...cards];
        temp[selectedCard].imageFile = null;
        temp[selectedCard].image = null;
        temp[selectedCard].title = "";
        temp[selectedCard].info = "15";

        if (previousData[selectedCard].imageStatus == "current") {
            temp[selectedCard].imageStatus = "delete";
        } else {
            temp[selectedCard].imageStatus = "";
        }

        setCards([...temp]);
    }

    function handleUploadImages(gameID) {
        const uploadFormData = new FormData();
        uploadFormData.append("gameID", gameID);

        const uploadConfig = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };

        const imageIndex = [];
        const deleteImages = [];
        for (const [index, card] of cards.entries()) {
            if (card.imageStatus == "create" || card.imageStatus == "update" || card.imageStatus == "delete") {
                uploadFormData.append("files", card.imageFile);
                if (card.imageStatus == "create") {
                    imageIndex.push({ card: (index + 1).toString(), file: card.imageFile.name, status: card.imageStatus });
                }
                if (card.imageStatus == "update") {
                    imageIndex.push({
                        card: (index + 1).toString(),
                        file: card.imageFile.name,
                        prevFile: previousData[index].info,
                        status: card.imageStatus
                    });
                }
                if (card.imageStatus == "delete") {
                    deleteImages.push({ card: (index + 1).toString(), file: previousData[index].info, status: card.imageStatus });
                }
            }
        }
        uploadFormData.append("imageIndex", JSON.stringify(imageIndex));
        uploadFormData.append("deleteImages", JSON.stringify(deleteImages));

        Axios.post("/pods/winThePointsAdmin/uploadImages", uploadFormData, uploadConfig)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    navigate(-1);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div>
            <Row>
                <Col sm={3} style={GameTemplateStyles.controlsContainer}>
                    <Row>
                        <Col>
                            <Button onClick={handleCardGoDown}>
                                <i className="fa-solid fa-chevron-down"></i>
                            </Button>
                        </Col>
                        <Col style={GameTemplateStyles.columnRight}>
                            <Button onClick={handleCardGoUp}>
                                <i className="fa-solid fa-chevron-up"></i>
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Form.Control ref={hiddenInput} type="file" style={GameTemplateStyles.input} onChange={handleFileClick} />
                            <Button onClick={handleTextBtnClick} variant={cards[selectedCard].title == "highview.image" ? "primary" : "success"}>
                                Text
                            </Button>
                            <Button onClick={handleOpenInput} variant={cards[selectedCard].title == "highview.image" ? "success" : "primary"}>
                                Image
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    {cards[selectedCard].title != "highview.image" && (
                        <div>
                            <Row>
                                <Col style={GameTemplateStyles.titleContainer}>
                                    Title:
                                    <Form.Control
                                        ref={titleTextControl}
                                        type="text"
                                        value={cards[selectedCard].title}
                                        onChange={handleOnChange}
                                        maxLength={20}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={GameTemplateStyles.counter}>{cards[selectedCard].title.length}/20</Col>
                            </Row>
                            <br />
                            <Row>
                                <Col style={GameTemplateStyles.fontContainer}>Font Size: {cards[selectedCard].info}</Col>
                                <Col>
                                    <Button onClick={handleFontGoDown}>
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </Button>
                                    <Button onClick={handleFontGoUp}>
                                        <i className="fa-solid fa-chevron-up"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>

                <Col style={{ ...GameTemplateStyles.centerContainer, height: gamesHub.height }}>
                    <Row>
                        {cards.map((card, index) => {
                            return (
                                <Col
                                    key={index}
                                    sm={3}
                                    style={index == selectedCard ? GameTemplateStyles.selectedPlacement : GameTemplateStyles.placement}
                                >
                                    {card.title == "highview.image" ? (
                                        <div
                                            style={{
                                                ...GameTemplateStyles.controlBox,
                                                backgroundImage: `url(${card.image}`
                                            }}
                                            onClick={handleCardClick.bind(this, index)}
                                        ></div>
                                    ) : (
                                        <div
                                            style={{ ...GameTemplateStyles.controlBox, fontSize: `${card.info}px` }}
                                            onClick={handleCardClick.bind(this, index)}
                                        >
                                            {card.title}
                                        </div>
                                    )}
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
                <Col sm={3} style={GameTemplateStyles.rightContainer}>
                    <Row>
                        <Col style={GameTemplateStyles.fileNameContainer}>
                            File name:
                            <Form.Group>
                                <Form.Control
                                    isInvalid={fileName.touched && !fileName.valid ? true : false}
                                    type="text"
                                    onBlur={handleFileNameTouched}
                                    value={fileName.value}
                                    onChange={handleFileNameTextChanged}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a file name!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={GameTemplateStyles.liveContainer}>
                            Live:
                            <Switch
                                className="customCSS-Content-New-Item-Switch"
                                onChange={handleLiveSwitchChange}
                                checked={live}
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={GameTemplateStyles.saveButtonContainer}>
                            {props.modify ? <Button onClick={handleUpdateButton}>Update</Button> : <Button onClick={handleSaveButton}>Save</Button>}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default GameTemplate;
