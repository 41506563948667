export const UPDATE_TITLE = "UPDATE_TITLE";
export const UPDATE_HEIGHT = "UPDATE_HEIGHT";
export const UPDATE_BACKGROUND_COLOR = "UPDATE_BACKGROUND_COLOR";
export const UPDATE_TITLE_COLOR = "UPDATE_TITLE_COLOR";
export const UPDATE_SHOW_HELP = "UPDATE_SHOW_HELP";
export const UPDATE_HELP_VARIANT = "UPDATE_HELP_VARIANT";
export const UPDATE_HELP_METHOD = "UPDATE_HELP_METHOD";

export const UpdateTitle = (val) => {
    return {
        type: UPDATE_TITLE,
        value: val
    };
};

export const UpdateHeight = (val) => {
    return {
        type: UPDATE_HEIGHT,
        value: val
    };
};

export const UpdateBackgroundColor = (val) => {
    return {
        type: UPDATE_BACKGROUND_COLOR,
        value: val
    };
};

export const UpdateTitleColor = (val) => {
    return {
        type: UPDATE_TITLE_COLOR,
        value: val
    };
};

export const UpdateShowHelp = (val) => {
    return {
        type: UPDATE_SHOW_HELP,
        value: val
    };
};

export const UpdateHelpMethod = (val) => {
    return {
        type: UPDATE_HELP_METHOD,
        value: val
    };
};

export const UpdateHelpVariant = (val) => {
    return {
        type: UPDATE_HELP_VARIANT,
        value: val
    };
};
