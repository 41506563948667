export const inside = {
    backgroundColor: "white",
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textAlign: "center",
    wordBreak: "break-all"
};

export const cover = {
    backgroundColor: "blue",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    zIndex: "1",
    borderRight: "3px solid white",
    borderLeft: "3px solid white",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "3.5rem"
};

export const innerContaner = {
    width: "100%",
    height: "100%",
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // border: "1px solid red",
    position: "absolute"
};

export const cardContainer = {
    // border: "1px solid black",
    width: "50%",
    height: "70%",
    position: "absolute",
    margin: 0,
    padding: 0
};
