export const centerContainer = {
    // border: "1px solid black",
    height: "100%"
};

export const placement = {
    // border: "1px solid red",
    height: "125px",
    position: "relative",
    padding: 0
};

export const card = {
    // border: "1px solid yellow",
    height: "125px",
    position: "absolute",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
};

export const helpKeys = {
    textAlign: "center"
};
