import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Card } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/controller";

//COMPONENTS
import AddUserModal from "./addUser";

function Controller() {
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [addModal, setAddModal] = useState({
        open: false
    });

    function handleAddModalClose() {
        setAddModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleAddModalOpen() {
        setAddModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Games Hub"));
        dispatch(GS_navSettingsActions.UpdateSelected("Games Hub"));
        dispatch(GS_navSettingsActions.UpdateStatic(false));
        dispatch(GS_navSettingsActions.UpdateStaticText(""));

        onLoad();
    }, []);

    function onLoad() {
        Axios.post("/pods/gamesHubAdmin/loadUsers")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setUsers(data.users);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={ControllerStyles.body}>
            <Row>
                <Col style={ControllerStyles.columnRight}>
                    <Button onClick={handleAddModalOpen}>New User</Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    {users.map((user, index) => {
                        return (
                            <div key={index}>
                                <Card style={ControllerStyles.cardStyle}>
                                    <Card.Body>
                                        <Row>
                                            <Col>Name: {user.fullname}</Col>
                                            <Col>Email: {user.email}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <br />
                            </div>
                        );
                    })}
                </Col>
            </Row>
            <AddUserModal open={addModal.open} closeFunction={handleAddModalClose} />
        </div>
    );
}

export default Controller;
