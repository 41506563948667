import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";

//ACTIONS
import * as GamesHubActions from "../../../../envStore/actions/gamesHub";

//STYLES
import * as MainMenuStyles from "../styles/mainMenu";

function MainMenu() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [games, setGames] = useState([{ title: "Win the Points", image: "winThePoints/mainLogo.png", link: "winThePoints" }]);
    const gamesHub = useSelector((state) => state.gamesHub);

    const [columnWidth, setColumnWidth] = useState(12);
    const [rowWidth, setRowWidth] = useState("25%");

    useEffect(() => {
        dispatch(GamesHubActions.UpdateTitle("Games Hub - Main Menu"));
        dispatch(GamesHubActions.UpdateBackgroundColor("#add8e6"));
        dispatch(GamesHubActions.UpdateTitleColor("red"));
        dispatch(GamesHubActions.UpdateShowHelp(false));

        if (games.length == 1) {
            setColumnWidth(12);
            setRowWidth("25%");
        } else if (games.length == 2) {
            setColumnWidth(6);
            setRowWidth("50%");
        } else if (games.length == 3) {
            setColumnWidth(4);
            setRowWidth("75%");
        } else {
            setColumnWidth(3);
            setRowWidth("100%");
        }
    }, []);

    function handleGameClick(link) {
        navigate(`./${link}`);
    }

    return (
        <div>
            <Row>
                <Col sm={2}></Col>
                <Col style={{ ...MainMenuStyles.menuContainer, height: gamesHub.height }}>
                    <div style={{ width: rowWidth }}>
                        <Row>
                            {games.map((game, index) => {
                                return (
                                    <Col
                                        key={index}
                                        sm={columnWidth}
                                        style={MainMenuStyles.gameContainer}
                                        onClick={handleGameClick.bind(this, game.link)}
                                    >
                                        <div style={MainMenuStyles.gameInnerContainer}>
                                            <p style={MainMenuStyles.gameTitle}>{game.title}</p>
                                            <Image style={MainMenuStyles.gameImage} src={`/content/gamesHub/${game.image}`} />
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                    {/* <Row>
                        {games.map((game, index) => {
                            return (
                                <Col key={index} sm={3} >
                                    
                                </Col>
                            );
                        })}
                    </Row> */}
                </Col>
                <Col sm={2}></Col>
            </Row>
        </div>
    );
}

export default MainMenu;
