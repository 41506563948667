import React from "react";

//PODS
//--ADMINISTRATOR
import HVSAdminLoginControl from "./administration/HVSLoginForm/loginControl";

//--ADMIN
import GamesHubAdmin from "./gamesHub/admin/podRouter";

//--ADMIN-STYLES

//--FRONTEND

function EnvironmentPodsMap(props) {
    return (
        <div>
            {props.podCode == "loginForm" && <HVSAdminLoginControl />}
            {/* podCode > component */}

            {/* ADMIN */}
            {props.podCode == "CPOD_GAMESHUB_ADMIN" && <GamesHubAdmin />}

            {/* FRONTEND */}
        </div>
    );
}

export default EnvironmentPodsMap;
