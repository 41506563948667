import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Axios from "axios";

//COMPONENTS

function StyleDesign() {
    const dispatch = useDispatch();
    const [adminStyle, setAdminStyle] = useState("");

    useEffect(() => {
        Axios.post("/pods/stylingPortalAdmin/getCurrentAdmin")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setAdminStyle(data.admin);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    return <div></div>;
}

export default StyleDesign;
