import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Modal, Button } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GamesHubActions from "../../../../../../envStore/actions/gamesHub";

//STYLES
import * as WinThePointsStyles from "../styles/winThePoints";

//COMPONENTS
import Card from "./card";

function WinThePoints() {
    const dispatch = useDispatch();
    const gamesHub = useSelector((state) => state.gamesHub);
    const user = useSelector((state) => state.user);

    const [transitionStyle, setTransitionStyle] = useState({});
    const [randPositions, setRandPositions] = useState([]);
    const [chooseNumber, setChooseNumber] = useState("");

    const [uncovered, setUncovered] = useState(false);
    const [shuffled, setShuffled] = useState(false);
    const [showingNumbers, setShowingNumbers] = useState(false);

    const [helpModal, setHelpModal] = useState(false);

    function handleHelpModalClose() {
        setHelpModal(false);
    }

    function handleHelpModalOpen() {
        setHelpModal(true);
    }

    const locations = {
        card0: useRef(),
        card1: useRef(),
        card2: useRef(),
        card3: useRef(),
        card4: useRef(),
        card5: useRef(),
        card6: useRef(),
        card7: useRef(),
        card8: useRef(),
        card9: useRef(),
        card10: useRef(),
        card11: useRef(),
        card12: useRef(),
        card13: useRef(),
        card14: useRef(),
        card15: useRef(),
        card16: useRef(),
        card17: useRef(),
        card18: useRef(),
        card19: useRef()
    };

    const [cards, setCards] = useState([
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false },
        { title: "", info: "15", number: "", image: null, top: 0, left: 0, open: false, selected: false, showNumber: false }
    ]);

    const placements = Array(20).fill(0);

    useEffect(() => {
        dispatch(GamesHubActions.UpdateTitle("Win The Points"));
        dispatch(GamesHubActions.UpdateBackgroundColor("#6495ED"));
        dispatch(GamesHubActions.UpdateTitleColor("white"));
        dispatch(GamesHubActions.UpdateShowHelp(true));
        dispatch(GamesHubActions.UpdateHelpMethod(handleHelpModalOpen));
        dispatch(GamesHubActions.UpdateHelpVariant("warning"));

        const newPositions = randomPositions(randPositions);
        console.log(newPositions);
        setRandPositions(newPositions);
        const tempCards = [...cards];

        const data = { userID: user.id };
        Axios.post("/pods/winThePointsAdmin/getLiveGame", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    for (const [index, card] of tempCards.entries()) {
                        card.title = data.cards[`card${index + 1}`];
                        card.info = data.cards[`info${index + 1}`];
                        card.number = (newPositions[index] + 1).toString();
                        card.top = locations[`card${newPositions[index]}`].current.offsetTop;
                        card.left = locations[`card${newPositions[index]}`].current.offsetLeft;

                        if (data.cards[`card${index + 1}`] == "highview.image") {
                            card.image = data.images[`image${index + 1}`];
                        }
                    }
                }

                setCards(tempCards);
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        window.addEventListener("keypress", handleOnKeyPress);
        return () => {
            window.removeEventListener("keypress", handleOnKeyPress);
        };
    }, [chooseNumber, uncovered, showingNumbers]);

    useEffect(() => {
        window.addEventListener("keypress", handleOnKeyPress_Shuffle);
        return () => {
            window.removeEventListener("keypress", handleOnKeyPress_Shuffle);
        };
    }, []);

    function randomPositions(oldArray) {
        const temp = [];
        let index = 0;
        while (index < 20) {
            const randomNumber = Math.floor(Math.random() * 20);
            if (!temp.includes(randomNumber) && oldArray[index] != randomNumber) {
                temp.push(randomNumber);
                index++;
            }
        }

        return temp;
    }

    function reSuffle() {
        setTransitionStyle({ transition: "all 0.5s ease-out" });
        const newPositions = randomPositions(randPositions);
        const tempCards = [...cards];

        for (const [index, card] of tempCards.entries()) {
            card.number = (newPositions[index] + 1).toString();
            card.top = locations[`card${newPositions[index]}`].current.offsetTop;
            card.left = locations[`card${newPositions[index]}`].current.offsetLeft;
        }

        setCards(tempCards);
    }

    function handleOnKeyPress_Shuffle(event) {
        if (event.key == "s" || event.key == "S") {
            if (!uncovered && checkCardsNumber()) {
                reSuffle();
                setShuffled(true);
            }
        }
    }

    function handleOnKeyPress(event) {
        if (/[0-9.]/.test(event.key)) {
            if (showingNumbers) {
                deselectAll();
                const temp = [...cards];
                const newString = chooseNumber + event.key.toString();

                temp.find((card) => card.number == newString).selected = true;
                setChooseNumber(newString);
            }
        }
        if (event.key == "Enter") {
            if (chooseNumber < 21) {
                deselectAll();
                handleOpenCard(chooseNumber);
            }
            setChooseNumber("");
        }
        if (event.key == "n" || event.key == "N") {
            if (!uncovered) {
                showAllNumbers();
                setShowingNumbers(true);
            }
        }
        if (event.key == "u" || event.key == "U") {
            if (!shuffled && checkCardsNumber()) {
                handleUncoverAll();
            }
        }

        if (event.key == "c" || event.key == "C") {
            if (!shuffled && checkCardsNumber()) {
                handleCoverAll();
            }
        }
        if (event.key == "h" || event.key == "H") {
            setHelpModal(true);
        }
    }

    function deselectAll() {
        for (const card of cards) {
            card.selected = false;
        }
    }

    function showAllNumbers() {
        const temp = [...cards];

        for (const card of temp) {
            if (!card.open) {
                card.showNumber = true;
            }
        }

        setCards(temp);
    }

    function checkCardsNumber() {
        let check = true;
        for (const card of cards) {
            if (card.showNumber) {
                check = false;
            }
        }
        return check;
    }

    function handleUncoverAll() {
        const temp = [...cards];

        for (const card of temp) {
            card.open = true;
        }

        setCards(temp);
        setUncovered(true);
    }

    function handleCoverAll() {
        const temp = [...cards];

        for (const card of temp) {
            card.open = false;
        }

        setCards(temp);
        setUncovered(false);
    }

    function handleOpenCard(index) {
        const temp = [...cards];

        temp.find((card) => card.number == index).open = true;
        temp.find((card) => card.number == index).showNumber = false;
    }

    return (
        <div>
            <Row>
                <Col sm={3}></Col>
                <Col style={{ ...WinThePointsStyles.centerContainer, height: gamesHub.height }}>
                    <Row>
                        {placements.map((place, index) => {
                            return <Col ref={locations[`card${index}`]} key={index} sm={3} style={WinThePointsStyles.placement}></Col>;
                        })}
                        {cards.map((card, index) => {
                            return (
                                <Col
                                    key={index}
                                    sm={3}
                                    style={{ ...WinThePointsStyles.card, ...transitionStyle, top: `${card.top}px`, left: `${card.left}px` }}
                                >
                                    <Card
                                        open={card.open}
                                        text={card.title}
                                        number={card.number}
                                        selected={card.selected}
                                        showNumber={card.showNumber}
                                        fontSize={card.info}
                                        image={card.image}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
                <Col sm={3}></Col>
            </Row>
            <Modal show={helpModal} onHide={handleHelpModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Win The Points - Controls</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3} style={WinThePointsStyles.helpKeys}>
                            <strong>U</strong>
                        </Col>
                        <Col>
                            Uncover the cards <br /> **This will be disabled once you start a shuffle
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={3} style={WinThePointsStyles.helpKeys}>
                            <strong>C</strong>
                        </Col>
                        <Col>Cover the cards</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={3} style={WinThePointsStyles.helpKeys}>
                            <strong>S</strong>
                        </Col>
                        <Col>
                            Shuffle the cards <br /> **This will be disabled once you show the numbers on the cards
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={3} style={WinThePointsStyles.helpKeys}>
                            <strong>N</strong>
                        </Col>
                        <Col>Show numbers on the cards</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={3} style={WinThePointsStyles.helpKeys}>
                            <strong>Numbers + Enter</strong>
                        </Col>
                        <Col>To open the numbered card</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={3} style={WinThePointsStyles.helpKeys}>
                            <strong>H</strong>
                        </Col>
                        <Col>Show Help</Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelpModalClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default WinThePoints;
