import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as AddUserStyles from "../styles/addUser";

function AddUser(props) {
    const dispatch = useDispatch();
    const defaultFormData = {
        values: {
            name: "",
            email: ""
        },
        valids: {
            name: false,
            email: false,
            password: false,
            confirmPassword: false
        },
        touched: {
            name: false,
            email: false,
            password: false,
            confirmPassword: false
        },
        formValid: false
    };
    const [formData, setFormData] = useState(defaultFormData);

    const [modal, setModal] = useState({
        open: false,
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleTextChange(event) {
        const { name, value } = event.target;

        const values = { ...formData.values };
        const valids = { ...formData.valids };

        if (name == "name" || name == "email") {
            values[name] = value;
        }

        let isValid = false;
        if (name == "email") {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
                isValid = true;
            }
        } else {
            if (value != "") {
                isValid = true;
            }
        }

        valids[name] = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, values, valids, formValid: allTrue };
        });
    }

    function handleOnTouched(event) {
        const { name } = event.target;

        const touched = { ...formData.touched };

        touched[name] = true;

        setFormData((prevState) => {
            return { ...prevState, touched };
        });
    }

    function handleOnSubmit() {
        if (!formData.formValid) {
            switchAllTouches();
        } else {
            const data = {
                name: formData.values.name,
                email: formData.values.email,
                password: document.getElementById("password").value,
                confirmPassword: document.getElementById("confirmPassword").value
            };
            Axios.post("/pods/gamesHubAdmin/addUser", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setFormData(defaultFormData);
                        props.closeFunction();
                    } else {
                        setModal({ message: data.message, open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(formData.touched).map((k) => ({ [k]: true })));
        setFormData((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    return (
        <div>
            <Modal show={props.open} onHide={props.closeFunction}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col style={AddUserStyles.formLabel}>Name:</Col>
                        <Col sm={9}>
                            <Form.Group>
                                <Form.Control
                                    isInvalid={formData.touched.name && !formData.valids.name ? true : false}
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleTextChange}
                                    onBlur={handleOnTouched}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a name!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={AddUserStyles.formLabel}>Email:</Col>
                        <Col sm={9}>
                            <Form.Group>
                                <Form.Control
                                    isInvalid={formData.touched.email && !formData.valids.email ? true : false}
                                    type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleTextChange}
                                    onBlur={handleOnTouched}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a valid email!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={AddUserStyles.formLabel}>Password:</Col>
                        <Col sm={9}>
                            <Form.Group>
                                <Form.Control
                                    isInvalid={formData.touched.password && !formData.valids.password ? true : false}
                                    type="password"
                                    name="password"
                                    id="password"
                                    value={formData.password}
                                    onChange={handleTextChange}
                                    onBlur={handleOnTouched}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a password!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={AddUserStyles.formLabel}>Confirm Password:</Col>
                        <Col sm={9}>
                            <Form.Group>
                                <Form.Control
                                    isInvalid={formData.touched.confirmPassword && !formData.valids.confirmPassword ? true : false}
                                    type="password"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleTextChange}
                                    onBlur={handleOnTouched}
                                />
                                <Form.Control.Feedback type="invalid">Please confirm your password!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleOnSubmit}>Add</Button>
                    <Button onClick={props.closeFunction}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User: Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddUser;
